import { ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, Injector, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { DataTableDirective } from "angular-datatables";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { DistributorsService } from "./distributors.service";
import { AlertService, BaseDatatableComponent, IApiListQueryParameter, IDataTableParameters } from "@impacgroup/angular-baselib";
import { DistributorObj, DistributorStatus, DistributorStatusArray } from "../../api-models/Checklist";
import { DistributorsMainComponent } from "./distributors.main.component";
import * as moment from "moment";
import "rxjs/add/operator/take";
import { TourDistributionDialogComponent } from "src/app/modal/tourdistribution-dialog.component";
import { ToursService } from "../tours/tours.service";
import { TourDialogModel, TourDistributorsCreateModel } from "src/app/api-models/Tour";

class FilterDataObject {
    searchTerm: string;
    showAsm: boolean;
    isActiveOnly: boolean;
    isInactiveOnly: boolean;
    isDisabledOnly: boolean;
    isStatus: DistributorStatus | "undefined";
    fromPostCode: string;
    toPostCode: string;
    city: string;
}

@Component({
    selector: "app-distributors",
    templateUrl: "./distributoroverview.component.html",
    styleUrls: ["distributoroverview.component.scss"]
})
export class DistributorOverviewComponent extends BaseDatatableComponent implements OnInit, OnDestroy {
    public UTCDATEFORMAT: string = "";

    @ViewChild(DataTableDirective, { static: false })
    datatableElement: DataTableDirective;
    rows: DistributorObj[] = [];
    bsModalRef: BsModalRef;

    showAsm = false;
    isActiveOnly = true;
    isInactiveOnly = false;
    isDisabledOnly = false;
    isSelectAll = false;
    selectedTotal: number = 0;
    recordsReceived: number = 0;
    searchTerm: string = "";
    isStatus?: DistributorStatus | "undefined";
    fromPostCode?: string;
    toPostCode?: string;
    city?: string;

    saleslineFilter = "";
    selectedLifecycleStatusFilter?: string;
    lifecycleStatusFilterItems: string[] = [];

    originSelectionFilter: FilterDataObject | null = null;

    selectedRows: {
        [key: string]: boolean;
    } = {};

    statusOptions = DistributorStatusArray;

    numbersOnlyPattern = /^\d*$/;

    private debounceTime = 300;
    private debounceFilterChangedTimeout?: number;

    private topSelectedCountRef: ComponentRef<DataTableSelectedCountComponent>;
    private bottomSelectedCountRef: ComponentRef<DataTableSelectedCountComponent>;

    constructor(
        private distributorsService: DistributorsService,
        private toursService: ToursService,
        private translateService: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private alertService: AlertService,
        parent: DistributorsMainComponent,
        protected cfr: ComponentFactoryResolver,
        protected cdr: ChangeDetectorRef,
        protected injector: Injector
    ) {
        super(translateService, {
            selectableRows: [15, 30, 45, 90, 500, 1000, 3000, 5000, 9000],
            stateSaveMode: distributorsService.datatableStateSaveMode,
            stateSaveComponent: parent,
            stateSaveStorageKey: "dtstate_distributors_list"
        });

        this.dtOptions.dom = '<"dataTables__top with-info"lfB<"selected-info-wrapper"i<"selection">>>rt<"dataTables__bottom"<"selected-info-wrapper"i<"selection">>p><"clear">';
        this.dtOptions.order = [[2, "asc"]];
        this.dtOptions.pageLength = 90;
        this.UTCDATEFORMAT = this.distributorsService.UTCDATEFORMAT;
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.distributorsService.getLifecycleStatusItems().subscribe((result) => {
                this.lifecycleStatusFilterItems = result;
            })
        );
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.topSelectedCountRef?.destroy();
        this.bottomSelectedCountRef?.destroy();
    }

    _dataTableTrigger() {
        return (dataTableParameters: IDataTableParameters, callback) => {
            // get api query parameters by datatable
            const queryParams = this.getApiQueryParamsByDataTable(dataTableParameters);
            // reset selection if search string has changed
            if (this.searchTerm !== queryParams.search.valueOf()) {
                if (this.originSelectionFilter === null) {
                    this.deselectAll();
                }
                this.searchTerm = queryParams.search.valueOf();
            }

            this.subscriptions.push(
                this.distributorsService
                    .distributorOverview({
                        params: queryParams,
                        isActive: this.isActiveOnly ? true : this.isInactiveOnly ? false : undefined,
                        disabled: this.isDisabledOnly ? true : undefined,
                        status: this.isStatus && this.isStatus !== "undefined" ? this.isStatus : undefined,
                        fromPostCode: this.fromPostCode,
                        toPostCode: this.toPostCode,
                        city: this.city,
                        saleslineFilter: this.saleslineFilter,
                        selectedLifecycleStatusFilter: this.selectedLifecycleStatusFilter && this.selectedLifecycleStatusFilter !== "undefined" ? this.selectedLifecycleStatusFilter : undefined
                    })
                    .subscribe((result) => {
                        this.rows = result.list;

                        // show all new entries as selected, not overwriting deselected
                        if (this.isSelectAll) {
                            this.rows.forEach((row) => {
                                if (this.selectedRows[row._id] !== false) {
                                    this.selectedRows[row._id] = true;
                                }
                            });
                        }
                        this.recordsReceived = result.count;

                        callback({
                            recordsTotal: result.total,
                            recordsFiltered: result.count,
                            data: []
                        });
                    })
            );
        };
    }

    _dataTableColums() {
        return [
            { data: "selected", orderable: false },
            { data: "vo", orderable: true },
            { data: "postcode", orderable: true },
            { data: "status", orderable: true },
            { data: "lifecycleStatus", orderable: true },
            { data: "salesline", orderable: true },
            { data: "checklistLastCreated", orderable: true },
            { data: "checklistLastCreatedByObj.lastname", orderable: true },
            { data: "asm", orderable: true },

            { data: "actions", orderable: false },
            { data: "disabled", orderable: false, className: "select-checkbox" }
        ];
    }

    public changeState(row: DistributorObj) {
        this.distributorsService.changeDistributorState(row).subscribe((result) => {
            row.version = result.version;
            row.disabled = result.disabled;
        });
    }

    showChecklists(id: string) {
        this.router.navigate(["/checklists/overview"], { queryParams: { distributorId: id } });
    }

    onChangeFilter() {
        if (!this.isSelectAll) {
            this.deselectAll();
        }
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw();
        });
    }

    selectAll() {
        this.selectedRows = {};
        this.rows.forEach((row) => {
            this.selectedRows[row._id] = true;
        });
        this.isSelectAll = true;
        this.onSelectionToggle();

        this.originSelectionFilter = {
            searchTerm: this.searchTerm,
            showAsm: this.showAsm,
            isActiveOnly: this.isActiveOnly,
            isInactiveOnly: this.isInactiveOnly,
            isDisabledOnly: this.isDisabledOnly,
            isStatus: this.isStatus,
            fromPostCode: this.fromPostCode,
            toPostCode: this.toPostCode,
            city: this.city
        };
    }

    deselectAll() {
        this.selectedRows = {};
        this.isSelectAll = false;
        this.onSelectionToggle();
    }

    onSelectionToggle() {
        let selected: number = 0;
        for (let distObjId in this.selectedRows) {
            if (this.selectedRows[distObjId] === !this.isSelectAll) {
                selected++;
            }
        }
        if (this.isSelectAll) {
            if (this.originSelectionFilter === null) {
                this.selectedTotal = this.recordsReceived - selected;
            } else {
                this.selectedTotal = this.selectedTotal - selected;
            }
        } else {
            this.selectedTotal = selected;
        }

        if (this.selectedTotal === 0) {
            this.originSelectionFilter = null;
        }
        this.refreshSelectionText();
    }

    onStatusSelected() {
        this.onChangeFilter();
    }

    postCodeChanged(postCode: string) {
        if (this.debounceFilterChangedTimeout) {
            window.clearTimeout(this.debounceFilterChangedTimeout);
        }
        this.debounceFilterChangedTimeout = window.setTimeout(() => {
            if (!this.numbersOnlyPattern.test(postCode)) {
                return;
            }

            this.onChangeFilter();
        }, this.debounceTime);
    }

    cityChanged() {
        this.searchInputChanged();
    }

    saleslineChanged() {
        this.searchInputChanged();
    }

    searchInputChanged() {
        if (this.debounceFilterChangedTimeout) {
            window.clearTimeout(this.debounceFilterChangedTimeout);
        }
        this.debounceFilterChangedTimeout = window.setTimeout(() => {
            this.onChangeFilter();
        }, this.debounceTime);
    }

    showTourDistributionDialog() {
        const queryParams: IApiListQueryParameter = { sort: "fromDate|asc" };
        this.subscriptions.push(
            this.toursService.list(queryParams, true).subscribe((result) => {
                const selectorOptions = result.list.map((model) => {
                    return {
                        name: model.name,
                        fromDate: moment(model.fromDate).format("YYYY-MM-DD").toString(),
                        toDate: moment(model.toDate).format("YYYY-MM-DD").toString(),
                        _id: model._id
                    };
                });

                const initialState = {
                    selectorOptions: selectorOptions
                };
                this.bsModalRef = this.modalService.show(TourDistributionDialogComponent, { initialState });
                this.subscriptions.push(this.bsModalRef.content.created.take(1).subscribe(this.createNewTour.bind(this)));
                this.subscriptions.push(this.bsModalRef.content.confirmed.take(1).subscribe(this.updateTourDistributors.bind(this)));
            })
        );
    }

    private createNewTour(tour: TourDialogModel) {
        this.subscriptions.push(
            this.toursService.create(tour).subscribe((result) => {
                this.updateTourDistributors(result._id);
            })
        );
    }

    private updateTourDistributors(tourId: string) {
        const distributors: string[] = [];
        for (let distributor in this.selectedRows) {
            if (this.selectedRows[distributor] === !this.isSelectAll) {
                distributors.push(distributor);
            }
        }

        const tourDistributors: TourDistributorsCreateModel = {
            tourId,
            distributors,
            isSelectedAll: this.isSelectAll
        };

        this.subscriptions.push(
            this.toursService
                .createTourDistributors(
                    tourDistributors,
                    this.originSelectionFilter === null
                        ? {
                              searchTerm: this.searchTerm,
                              isActiveOnly: this.isActiveOnly,
                              isInactiveOnly: this.isInactiveOnly,
                              isDisabledOnly: this.isDisabledOnly,
                              isStatus: this.isStatus,
                              fromPostCode: this.fromPostCode,
                              toPostCode: this.toPostCode,
                              city: this.city
                          }
                        : { ...this.originSelectionFilter }
                )
                .subscribe((result) => {
                    if (result.failcounter === 0) {
                        this.alertService.addSuccess(this.translateService.instant("tours.dialog.success"));
                        this.deselectAll();
                    } else {
                        this.alertService.addCustomError(this.translateService.instant(result.failcounter < 2 ? "tours.dialog.partialSuccess.singular" : "tours.dialog.partialSuccess.plural", { count: result.failcounter }));
                        this.deselectAll();
                    }
                    this.bsModalRef.hide();
                })
        );
    }

    private refreshSelectionText() {
        const topSelectionEl = document.querySelector(".dataTables__top .selection");
        const bottomSelectionEl = document.querySelector(".dataTables__bottom .selection");

        if (!this.topSelectedCountRef) {
            const f = this.cfr.resolveComponentFactory(DataTableSelectedCountComponent);
            this.topSelectedCountRef = f.create(this.injector, [], topSelectionEl);
        }
        if (!this.bottomSelectedCountRef) {
            const f = this.cfr.resolveComponentFactory(DataTableSelectedCountComponent);
            this.bottomSelectedCountRef = f.create(this.injector, [], bottomSelectionEl);
        }

        this.topSelectedCountRef.instance.selectedCount = this.selectedTotal;
        this.topSelectedCountRef.changeDetectorRef.detectChanges();

        this.bottomSelectedCountRef.instance.selectedCount = this.selectedTotal;
        this.bottomSelectedCountRef.changeDetectorRef.detectChanges();
    }
}

@Component({
    selector: "app-datatable-selected-count",
    template: `<span *ngIf="selectedCount > 0">{{ "distributors.list.selectedCount" | translate : { selectedCount: selectedCount } }} </span>`
})
export class DataTableSelectedCountComponent {
    public selectedCount: number = 0;
}
