export class PermissionsConfig {
    static readonly PERMISSIONS = {
        AUTHENTICATED: {
            andScopes: ["authenticated"],
            orScopes: []
        },
        GLOBAL: {
            // User may switch organizations
            andScopes: ["authenticated", "admin.organization.read", "global"],
            orScopes: []
        },
        "MENU.USERMANAGEMENT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.read", "admin.role.read", "admin.organization.read"]
        },
        "MENU.USERMANAGEMENT.USERS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.read"]
        },
        "MENU.USERMANAGEMENT.ROLES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.role.read"]
        },
        "MENU.USERMANAGEMENT.ORGANIZATIONS": {
            andScopes: ["authenticated", "admin.organization.read", "global"],
            orScopes: []
        },
        "MENU.LANGUAGES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.language.read"]
        },
        "MENU.MAIL": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailaccount.read", "admin.mailtemplate.read"]
        },
        "MENU.MAIL.MAILACCOUNTS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailaccount.read"]
        },
        "MENU.MAIL.MAILTEMPLATES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailtemplate.read"]
        },
        "MENU.FIREBASE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebaseconfig.read", "admin.firebasedevice.read", "admin.firebasenotification.read", "admin.firebasenotificationtemplate.read"]
        },
        "MENU.FIREBASE.FIREBASECONFIG": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebaseconfig.read"]
        },
        "MENU.FIREBASE.FIREBASEDEVICES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasedevice.read"]
        },
        "MENU.FIREBASE.FIREBASENOTIFICATIONS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasenotification.read"]
        },
        "MENU.FIREBASE.FIREBASENOTIFICATIONTEMPLATES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasenotificationtemplate.read"]
        },
        "MENU.SYSTEMHEALTH": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemlog.read", "admin.systemjob.read", "admin.systemresource.read", "admin.systemerror.read"] // TODO: add scopes from the other modules
        },
        "MENU.SYSTEMHEALTH.SYSTEMLOGMESSAGES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemlog.read"]
        },
        "MENU.SYSTEMHEALTH.SYSTEMJOBPERFORMANCES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemjob.read"]
        },
        "MENU.SYSTEMHEALTH.SYSTEMRESOURCESNAPSHOTS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemresource.read"]
        },
        "MENU.SYSTEMHEALTH.SYSTEMERRORS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemerror.read"]
        },
        "MENU.POSTCODEAREAS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.postcodearea.read", "admin.postcodeuser.read"]
        },
        "MENU.POSTCODEAREAS.OVERVIEW": {
            andScopes: ["authenticated"],
            orScopes: ["admin.postcodearea.read"]
        },
        "MENU.POSTCODEUSERS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.postcodeuser.read"]
        },
        "MENU.FLYERIMAGES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.flyerimage.read"]
        },
        "MENU.INTERESTEDPARTIES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.interestedparty.read"]
        },
        "MENU.CHECKLISTTEMPLATE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.checklisttemplate.read"]
        },
        "MENU.DISTRIBUTORS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.distributors.read", "admin.distributors.update"]
        },
        "MENU.DISTRIBUTORS.OVERVIEW": {
            andScopes: ["authenticated"],
            orScopes: ["admin.distributors.read"]
        },
        "MENU.DISTRIBUTORS.IMPORT": {
            andScopes: ["authenticated", "admin.distributors.read", "admin.distributors.update"],
            orScopes: []
        },
        "MENU.CHECKLISTS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.checklist.read", "admin.checklist.export"]
        },
        "MENU.CHECKLISTS.OVERVIEW": {
            andScopes: ["authenticated"],
            orScopes: ["admin.checklist.read"]
        },
        "MENU.CHECKLISTS.EXPORT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.checklist.export"]
        },
        "MENU.SHOPITEMS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.shopitem.read"]
        },
        "MENU.ORDERITEMS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.orderitem.read"]
        },
        "MENU.VISITREPORTS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.visitreport.read"]
        },
        "USERMANAGEMENT.USERS.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.create"]
        },
        "USERMANAGEMENT.USERS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.read"]
        },
        "USERMANAGEMENT.USERS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.update"]
        },
        "USERMANAGEMENT.USERS.VIEW": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.read"]
        },
        "USERMANAGEMENT.USERS.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.delete"]
        },
        "USERMANAGEMENT.ROLES.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.role.create"]
        },
        "USERMANAGEMENT.ROLES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.role.read"]
        },
        "USERMANAGEMENT.ROLES.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.role.update"]
        },
        "USERMANAGEMENT.ROLES.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.role.delete"]
        },
        "USERMANAGEMENT.ORGANIZATIONS.ADD": {
            andScopes: ["authenticated", "global"],
            orScopes: ["admin.organization.create"]
        },
        "USERMANAGEMENT.ORGANIZATIONS.LIST": {
            andScopes: ["authenticated", "global"],
            orScopes: ["admin.organization.read"]
        },
        "USERMANAGEMENT.ORGANIZATIONS.EDIT": {
            andScopes: ["authenticated", "global"],
            orScopes: ["admin.organization.update"]
        },
        "USERMANAGEMENT.ORGANIZATIONS.DELETE": {
            andScopes: ["authenticated", "global"],
            orScopes: ["admin.organization.delete"]
        },
        "LANGUAGES.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.language.create"]
        },
        "LANGUAGES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.language.read"]
        },
        "LANGUAGES.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.language.update"]
        },
        "LANGUAGES.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.language.delete"]
        },
        "MAIL.MAILTEMPLATES.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailtemplate.create"]
        },
        "MAIL.MAILTEMPLATES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailtemplate.read"]
        },
        "MAIL.MAILTEMPLATES.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailtemplate.update"]
        },
        "MAIL.MAILTEMPLATES.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailtemplate.delete"]
        },
        "MAIL.MAILACCOUNTS.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailaccount.create"]
        },
        "MAIL.MAILACCOUNTS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailaccount.read"]
        },
        "MAIL.MAILACCOUNTS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailaccount.update"]
        },
        "MAIL.MAILACCOUNTS.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailaccount.delete"]
        },
        "FIREBASE.FIREBASECONFIG.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebaseconfig.update"]
        },
        "FIREBASE.FIREBASEDEVICES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasedevice.read"]
        },
        "FIREBASE.FIREBASEDEVICES.VIEW": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasedevice.read"]
        },
        "FIREBASE.FIREBASEDEVICES.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasedevice.delete"]
        },
        "FIREBASE.FIREBASENOTIFICATIONS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasenotification.read"]
        },
        "FIREBASE.FIREBASENOTIFICATIONS.SEND": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasenotification.send"]
        },
        "FIREBASE.FIREBASENOTIFICATIONS.VIEW": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasenotification.read"]
        },
        "FIREBASE.FIREBASENOTIFICATIONTEMPLATES.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasenotificationtemplate.create"]
        },
        "FIREBASE.FIREBASENOTIFICATIONTEMPLATES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasenotificationtemplate.read"]
        },
        "FIREBASE.FIREBASENOTIFICATIONTEMPLATES.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasenotificationtemplate.update"]
        },
        "FIREBASE.FIREBASENOTIFICATIONTEMPLATES.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasenotificationtemplate.delete"]
        },
        "FIREBASE.FIREBASENOTIFICATIONTEMPLATES.PUSHDATACONTENT": {
            // add or edit push data object
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasenotificationtemplate.pushdatacontent"]
        },
        "FIREBASE.FIREBASENOTIFICATIONTEMPLATES.PUSHNONSILENT": {
            // add or edit silent option (default is true)
            andScopes: ["authenticated"],
            orScopes: ["admin.firebasenotificationtemplate.pushdatacontent"]
        },
        "SYSTEMHEALTH.SYSTEMLOGMESSAGES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemlog.read"]
        },
        "SYSTEMHEALTH.SYSTEMJOBPERFORMANCES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemjob.read"]
        },
        "SYSTEMHEALTH.SYSTEMRESOURCESNAPSHOTS.STATISTICS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemresource.read"]
        },
        "SYSTEMHEALTH.SYSTEMERRORS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemerror.read"]
        },
        "SYSTEMHEALTH.SYSTEMERRORS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemerror.update"]
        },
        "POSTCODEAREAS.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.postcodearea.create"]
        },
        "POSTCODEAREAS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.postcodearea.read"]
        },
        "POSTCODEAREAS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.postcodearea.update"]
        },
        "POSTCODEAREAS.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.postcodearea.delete"]
        },
        "POSTCODEUSERS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.postcodeuser.read"]
        },
        "POSTCODEUSERS.READ": {
            andScopes: ["authenticated"],
            orScopes: ["admin.postcodeuser.read"]
        },
        "POSTCODEUSERS.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.postcodeuser.create"]
        },
        "POSTCODEUSERS.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.postcodeuser.delete"]
        },
        "FLYERIMAGES.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.flyerimage.create"]
        },
        "FLYERIMAGES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.flyerimage.read"]
        },
        "FLYERIMAGES.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.flyerimage.update"]
        },
        "FLYERIMAGES.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.flyerimage.delete"]
        },
        "INTERESTEDPARTIES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.interestedparty.read"]
        },
        "INTERESTEDPARTIES.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.interestedparty.delete"]
        },
        "CHECKLISTTEMPLATE.READ": {
            andScopes: ["authenticated"],
            orScopes: ["admin.checklisttemplate.read"]
        },
        "CHECKLISTTEMPLATE.UPDATE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.checklisttemplate.update"]
        },
        "DISTRIBUTORS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.distributors.read"]
        },
        "DISTRIBUTORS.READ": {
            andScopes: ["authenticated"],
            orScopes: ["admin.distributors.read"]
        },
        "DISTRIBUTORS.UPDATE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.distributors.update"]
        },
        "DISTRIBUTORS.DISABLE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.distributors.disable"]
        },
        "CHECKLIST.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.checklist.read"]
        },
        "CHECKLIST.READ": {
            andScopes: ["authenticated"],
            orScopes: ["admin.checklist.read"]
        },
        "CHECKLIST.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.checklist.read"]
        },
        "CHECKLIST.EXPORTRESULTS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.checklist.exportresults"]
        },
        "CHECKLIST.UPDATE.MARK": {
            andScopes: ["authenticated"],
            orScopes: ["admin.checklist.update.marked"]
        },
        "CHECKLIST.UPDATE.APPROVE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.checklist.update.approved"]
        },
        "CHECKLIST.UPDATE.CONFIRM": {
            andScopes: ["authenticated"],
            orScopes: ["admin.checklist.update.confirmed"]
        },
        "SHOPITEMS.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.shopitem.create"]
        },
        "SHOPITEMS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.shopitem.read"]
        },
        "SHOPITEMS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.shopitem.update"]
        },
        "SHOPITEMS.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.shopitem.delete"]
        },
        "ORDERITEMS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.orderitem.read"]
        },
        "ORDERITEMS.EXPORT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.orderitem.read"]
        },
        "VISITREPORT.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.visitreport.read"]
        },
        "MENU.TOURS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.tour.read"]
        },
        "TOURS.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.tour.create"]
        },
        "TOURS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.tour.read"]
        },
        "TOURS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.tour.update"]
        },
        "TOURS.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.tour.delete"]
        }
    };
}
