import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { IApiListQueryParameter, BaseDatatableStateSaveMode } from "@impacgroup/angular-baselib";
import { map } from "rxjs/operators";
import { classToPlain, plainToClass } from "class-transformer";
import { Checklist, DistributorObj } from "../../api-models/Checklist";

export interface IChecklistsConfigService {
    checklistsAPI: string;
    utcDateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const ChecklistsConfigService = new InjectionToken<IChecklistsConfigService>("ChecklistsConfig");

export interface IExportChecklistResultsQueryParams extends IApiListQueryParameter {
    distributorId?: string;
    showDifferenceDetected?: string;
    showNoDifferenceDetected?: string;
    showWarningNoticesDetected?: string;
    showWarningsUnknown?: string;
    showNoWarningNoticesDetected?: string;
    showNeutral?: string;
    showMarked?: string;
    showApproved?: string;
    showApprovalConfirmed?: string;

    showAccessUnknown?: string;
    showAccessibl?: string;
    showNotAccessible?: string;

    date?: string;
    mode?: string;
    userFilter?: string;

    "status[]"?: string[];
    "templateNumber[]"?: string[];
}

@Injectable()
export class ChecklistsService {
    public UTCDATEFORMAT: string = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(@Inject(ChecklistsConfigService) private checklistsConfig: IChecklistsConfigService, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {
        this.UTCDATEFORMAT = this.checklistsConfig.utcDateFormat;
        this.datatableStateSaveMode = this.checklistsConfig.datatableStateSaveMode;
    }

    public checklistOverview(params: IApiListQueryParameter): Observable<{ list: Checklist[]; count: number; total: number }> {
        return this.http
            .get<any>(this.getChecklistsServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params: params as any,
                observe: "response"
            })
            .pipe(
                map((result) => {
                    let count = result.body.length;
                    let total = result.body.length;
                    try {
                        count = parseInt(result.headers.get("X-Pagination-Count"), 10);
                    } catch (e) {}
                    try {
                        total = parseInt(result.headers.get("X-Total-Count"), 10);
                    } catch (e) {}

                    return {
                        list: plainToClass(Checklist, result.body as any[]),
                        count: count,
                        total: total
                    };
                })
            );
    }

    public downloadChecklistPDF(id: string): Observable<HttpResponse<Blob>> {
        return this.http.get<Blob>(this.getChecklistsServiceUrl(id + "/pdf"), {
            headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType(),
            observe: "response",
            responseType: "blob" as "json"
        });
    }

    public getDistributors(): Observable<any> {
        return this.http
            .get<Object>(this.getChecklistsServiceUrl("distributors"), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(DistributorObj, result)));
    }

    public setMarkStateOnChecklist(id: string, state: boolean): Observable<void> {
        const data = { state };

        return this.http.patch<void>(this.getChecklistsServiceUrl(id + "/marked"), data, {
            headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType()
        });
    }

    public setApproveStateOnChecklist(id: string, state: boolean): Observable<void> {
        const data = { state };

        return this.http.patch<void>(this.getChecklistsServiceUrl(id + "/approved"), data, {
            headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType()
        });
    }

    public setConfirmStateOnChecklist(id: string, state: boolean): Observable<void> {
        const data = { state };

        return this.http.patch<void>(this.getChecklistsServiceUrl(id + "/confirmed"), data, {
            headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType()
        });
    }

    public checklistDetail(id: string): Observable<Checklist> {
        return this.http
            .get<Object>(this.getChecklistsServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(Checklist, result)));
    }

    public updateChecklist(checklist: Checklist): Observable<Checklist> {
        return this.http
            .patch<Object>(this.getChecklistsServiceUrl(checklist._id), classToPlain(Checklist), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(Checklist, result)));
    }

    public exportChecklists(): Observable<Blob> {
        return this.http.get(this.getChecklistsServiceUrl("export/"), {
            headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType(),
            responseType: "blob"
        });
    }

    public exportChecklistResults(params?: IExportChecklistResultsQueryParams): Observable<Blob> {
        return this.http.get(this.getChecklistsServiceUrl("exportresults/"), {
            headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType(),
            params: params as any,
            responseType: "blob"
        });
    }

    private getChecklistsServiceUrl(postfix: string): string {
        return this.checklistsConfig.checklistsAPI + postfix;
    }
}
