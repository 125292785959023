import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { ChecklistTemplateService } from "./checklisttemplate.service";
import { AlertService, BaseComponent, ConfirmDialogComponent } from "@impacgroup/angular-baselib";
import { ChecklistTemplate, ChecklistTemplateTemporary } from "../../api-models/ChecklistTemplate";

import "brace";
import "brace/mode/html";
import "brace/mode/json";
import "brace/theme/eclipse";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: "app-checklisttemplate",
    templateUrl: "./checklisttemplate.component.html",
    styleUrls: ["checklisttemplate.component.scss"]
})
export class ChecklistTemplateComponent extends BaseComponent implements OnInit {
    public UTCDATEFORMAT: string = "";

    public templates = [];
    public nextTemplateNumber: number | undefined;

    public templateNumber: number;
    public templateId: string;
    public checklistTemplate: ChecklistTemplate;
    public checklistTemplateTemporary: ChecklistTemplateTemporary = new ChecklistTemplateTemporary();

    public agreementWYSIWYGMode = false;

    public settingsHTML = { theme: "eclipse", mode: "html", options: { tabSize: 2 } };
    public settingsJSON = { theme: "eclipse", mode: "json", options: { tabSize: 2 } };

    constructor(
        private checklistTemplateService: ChecklistTemplateService,
        private translateService: TranslateService,
        private alertService: AlertService,
        private modalService: BsModalService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        super();
        this.UTCDATEFORMAT = this.checklistTemplateService.UTCDATEFORMAT;
    }

    ngOnInit() {
        this.refreshTemplateLinks();

        const paramsSub = this.route.params.subscribe((params) => {
            const paramsTemplateNumber = params.templateNumber;
            this.templateNumber = /^\d+$/.test(String(paramsTemplateNumber)) ? parseInt(String(paramsTemplateNumber), 10) : undefined;

            this.checklistTemplate = undefined;
            this.checklistTemplateTemporary = new ChecklistTemplateTemporary();

            if (!this.templateNumber) {
                return;
            }

            const getChecklistTemplateSub = this.checklistTemplateService.get(paramsTemplateNumber).subscribe((result) => {
                this.processChecklist(result);

                if (this.nextTemplateNumber == paramsTemplateNumber) {
                    this.refreshTemplateLinks();
                }
            });

            this.subscriptions.push(getChecklistTemplateSub);
        });
        this.subscriptions.push(paramsSub);
    }

    public toggleAgreementEditorMode() {
        this.agreementWYSIWYGMode = !this.agreementWYSIWYGMode;
    }

    public beautify(name: string) {
        try {
            this.checklistTemplateTemporary[name] = this.beautifyJSON(JSON.parse(this.checklistTemplateTemporary[name]));
        } catch (e) {
            const rawErrorMessage = e.toString();
            const elementName = this.translateService.instant(`checklisttemplate.${name}.title`);
            const readableErrorMessage = this.translateService.instant("checklisttemplate.editor.cantbeautify", { name: elementName, error: rawErrorMessage });
            this.alertService.addCustomError(readableErrorMessage);
        }
    }

    public save() {
        this.checklistTemplate.content = JSON.parse(this.checklistTemplateTemporary.content);
        this.checklistTemplate.photosNormal = JSON.parse(this.checklistTemplateTemporary.photosNormal);
        this.checklistTemplate.photosSilber = JSON.parse(this.checklistTemplateTemporary.photosSilber);
        this.checklistTemplate.photosGold = JSON.parse(this.checklistTemplateTemporary.photosGold);

        this.subscriptions.push(
            this.checklistTemplateService.update(this.templateId, this.checklistTemplate).subscribe((result) => {
                this.processChecklist(result);
                this.alertService.addSuccess(this.translateService.instant("checklisttemplate.update.success"));
            })
        );
    }

    public showCreateNewChecklistTemplateModal(templateNumber: number) {
        const initialState = {
            title: this.translateService.instant("checklisttemplate.createTemplate.title", { number: templateNumber }),
            message: this.translateService.instant("checklisttemplate.createTemplate.message", { number: templateNumber }),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        bsModalRef.content.confirmCallback = () => {
            this.router.navigate(["/checklisttemplate", templateNumber]);
        };
    }

    public migrateAll() {
        this.subscriptions.push(
            this.checklistTemplateService.migrateAll().subscribe((result) => {
                const { remainingCount, migrated, total } = result;
                this.alertService.addSuccess(this.translateService.instant("checklisttemplate.migration.success", { remainingCount, migrated, total }));
            })
        );
    }

    private processChecklist(result) {
        this.templateId = result._id;
        this.checklistTemplate = result;
        this.checklistTemplateTemporary.content = this.beautifyJSON(result.content);
        this.checklistTemplateTemporary.photosNormal = this.beautifyJSON(result.photosNormal);
        this.checklistTemplateTemporary.photosSilber = this.beautifyJSON(result.photosSilber);
        this.checklistTemplateTemporary.photosGold = this.beautifyJSON(result.photosGold);
    }

    private beautifyJSON(content: string | Object) {
        return JSON.stringify(content, null, "\t");
    }

    private refreshTemplateLinks() {
        const listChecklistTemplatesSub = this.checklistTemplateService.list().subscribe((result) => {
            this.templates = result.result.list;
            this.nextTemplateNumber = result.result.nextTemplateNumber;
        });

        this.subscriptions.push(listChecklistTemplatesSub);
    }
}
