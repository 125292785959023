<div class="content__inner">
    <header class="content__title">
        <h1>{{ "checklists.overview.list.title" | translate }}</h1>
        <small>{{ "checklists.overview.list.subtitle" | translate }}</small>
    </header>

    <div class="card" *appHasPermission="'CHECKLIST.LIST'">
        <div class="card-body">
            <h4 class="card-title">{{ "checklists.overview.list.card.title" | translate }}</h4>
            <div class="actions">
                <div *ngIf="chosenSingleTemplateNumber > 0">
                    <button class="float-right btn btn-outline-secondary btn--icon-text" *appHasPermission="'CHECKLIST.EXPORTRESULTS'" (click)="downloadChecklistResultsCSV()">
                        <i class="zmdi zmdi-download"></i> {{ "checklists.overview.list.card.export" | translate }}
                    </button>
                    <button class="float-right btn btn-outline-secondary btn--icon-text mr-3 ml-10" *appHasPermission="'CHECKLIST.EXPORTRESULTS'" (click)="downloadFilteredChecklistResultsCSV()">
                        <i class="zmdi zmdi-download"></i> {{ "checklists.overview.list.card.filteredExport" | translate }}
                    </button>
                </div>
                <div *ngIf="!chosenSingleTemplateNumber > 0">{{ "checklists.overview.list.card.exportNote" | translate }}</div>
            </div>

            <div class="form-group">
                <label>{{ "checklists.overview.filter.distributor.title" | translate }}</label>
                <ng-select id="distributorSelection" [items]="distributors" bindLabel="name" (change)="onChangeFilter()" [placeholder]="'checklists.overview.filter.distributor.placeholder' | translate" [(ngModel)]="selectedDistributor">
                </ng-select>
            </div>

            <div class="form-group">
                <label>{{ "checklists.overview.filter.display.title" | translate }}</label>
                <div class="checkbox">
                    <input type="checkbox" id="showAsm" [checked]="showAsm" (change)="showAsm = !showAsm" />
                    <label class="checkbox__label" for="showAsm">{{ "distributors.overview.filter.display.showAsm" | translate }}</label>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label>{{ "checklists.overview.filter.differenceStatus.title" | translate }}</label>
                        <div class="checkbox">
                            <input type="checkbox" id="showDifferenceDetected" [checked]="parent.showDifferenceDetected" (change)="parent.showDifferenceDetected = !parent.showDifferenceDetected; onChangeFilter()" />
                            <label class="checkbox__label" for="showDifferenceDetected"><i class="zmdi zmdi-flash text-danger"></i> {{ "checklists.overview.filter.differenceStatus.showDifferenceDetected" | translate }}</label>
                        </div>
                        <div class="checkbox">
                            <input type="checkbox" id="showNoDifferenceDetected" [checked]="parent.showNoDifferenceDetected" (change)="parent.showNoDifferenceDetected = !parent.showNoDifferenceDetected; onChangeFilter()" />
                            <label class="checkbox__label" for="showNoDifferenceDetected"
                                ><i class="zmdi zmdi-assignment-check text-success"></i> {{ "checklists.overview.filter.differenceStatus.showNoDifferenceDetected" | translate }}</label
                            >
                        </div>
                    </div>

                    <div class="form-group">
                        <label>{{ "checklists.overview.filter.warningStatus.title" | translate }}</label>
                        <div class="checkbox">
                            <input type="checkbox" id="showWarningNoticesDetected" [checked]="parent.showWarningNoticesDetected" (change)="parent.showWarningNoticesDetected = !parent.showWarningNoticesDetected; onChangeFilter()" />
                            <label class="checkbox__label" for="showWarningNoticesDetected"><i class="zmdi zmdi-alert-triangle text-danger"></i> {{ "checklists.overview.filter.warningStatus.showWarningNoticesDetected" | translate }}</label>
                        </div>
                        <div class="checkbox">
                            <input type="checkbox" id="showWarningsUnknown" [checked]="parent.showWarningsUnknown" (change)="parent.showWarningsUnknown = !parent.showWarningsUnknown; onChangeFilter()" />
                            <label class="checkbox__label" for="showWarningsUnknown"><i class="zmdi zmdi-pin-help text-warning"></i> {{ "checklists.overview.filter.warningStatus.showWarningsUnknown" | translate }}</label>
                        </div>
                        <div class="checkbox">
                            <input type="checkbox" id="showNoWarningNoticesDetected" [checked]="parent.showNoWarningNoticesDetected" (change)="parent.showNoWarningNoticesDetected = !parent.showNoWarningNoticesDetected; onChangeFilter()" />
                            <label class="checkbox__label" for="showNoWarningNoticesDetected"
                                ><i class="zmdi zmdi-assignment-check text-success"></i> {{ "checklists.overview.filter.warningStatus.showNoWarningNoticesDetected" | translate }}</label
                            >
                        </div>
                    </div>

                    <div class="form-group">
                        <label>{{ "checklists.overview.filter.approvalStatus.title" | translate }}</label>
                        <div class="checkbox">
                            <input type="checkbox" id="showNeutral" [checked]="parent.showNeutral" (change)="parent.showNeutral = !parent.showNeutral; onChangeFilter()" />
                            <label class="checkbox__label" for="showNeutral"><i class="zmdi zmdi-badge-check text-warning"></i> {{ "checklists.overview.filter.approvalStatus.showNeutral" | translate }}</label>
                        </div>
                        <div class="checkbox">
                            <input type="checkbox" id="showApproved" [checked]="parent.showApproved" (change)="parent.showApproved = !parent.showApproved; onChangeFilter()" />
                            <label class="checkbox__label" for="showApproved"><i class="zmdi zmdi-badge-check text-info"></i> {{ "checklists.overview.filter.approvalStatus.showApproved" | translate }}</label>
                        </div>
                        <div class="checkbox">
                            <input type="checkbox" id="showApprovalConfirmed" [checked]="parent.showApprovalConfirmed" (change)="parent.showApprovalConfirmed = !parent.showApprovalConfirmed; onChangeFilter()" />
                            <label class="checkbox__label" for="showApprovalConfirmed"><i class="zmdi zmdi-badge-check text-success"></i> {{ "checklists.overview.filter.approvalStatus.showApprovalConfirmed" | translate }}</label>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label>{{ "checklists.overview.filter.distributorAccess.title" | translate }}</label>
                        <div class="checkbox">
                            <input type="checkbox" id="showAccessUnknown" [checked]="showAccessUnknown" (change)="showAccessUnknown = !showAccessUnknown; onChangeFilter()" />
                            <label class="checkbox__label" for="showAccessUnknown"><i class="zmdi zmdi-help text-warning"></i> {{ "checklists.overview.filter.distributorAccess.showUnknown" | translate }}</label>
                        </div>
                        <div class="checkbox">
                            <input type="checkbox" id="showAccessible" [checked]="showAccessible" (change)="showAccessible = !showAccessible; onChangeFilter()" />
                            <label class="checkbox__label" for="showAccessible"><i class="zmdi zmdi-eye text-success"></i> {{ "checklists.overview.filter.distributorAccess.showAccessible" | translate }}</label>
                        </div>
                        <div class="checkbox">
                            <input type="checkbox" id="showNotAccessible" [checked]="showNotAccessible" (change)="showNotAccessible = !showNotAccessible; onChangeFilter()" />
                            <label class="checkbox__label" for="showNotAccessible"><i class="zmdi zmdi-minus-circle text-danger"></i> {{ "checklists.overview.filter.distributorAccess.showNotAccessible" | translate }}</label>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>{{ "checklists.overview.filter.distributorStatus.title" | translate }}</label>

                        <div class="checkbox" *ngFor="let statusOption of statusOptions">
                            <input type="checkbox" id="isStatus-{{ statusOption }}" [(ngModel)]="isStatus[statusOption]" (change)="onChangeFilter()" />
                            <label class="checkbox__label" for="isStatus-{{ statusOption }}">{{ "checklists.overview.filter.distributorStatus.options." + statusOption | translate }}</label>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>{{ "checklists.overview.filter.templateNumber.title" | translate }}</label>

                        <div class="checkbox" *ngFor="let templateNumber of templateNumbers">
                            <input type="checkbox" id="isTemplateNumber-{{ templateNumber }}" [(ngModel)]="isTemplateNumber[templateNumber]" (change)="onChangeFilter()" />
                            <label class="checkbox__label" for="isTemplateNumber-{{ templateNumber }}">{{ "checklists.overview.filter.templateNumber.options." + templateNumber | translate }}</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <button type="button" (click)="selectAll()" class="btn btn-success btn--icon-text float-left ml-2 mb-2">{{ "checklists.buttons.selectAll" | translate }}</button>
                    <button type="button" (click)="deselectAll()" class="btn btn-success btn--icon-text float-left ml-2 mb-2">{{ "checklists.buttons.deselectAll" | translate }}</button>
                </div>
            </div>

            <table datatable class="table table-bordered" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th id="selection"></th>
                        <th id="status"></th>
                        <th id="distributor">{{ "checklists.props.distributorAndVo" | translate }}</th>
                        <th id="distributorObj.status">{{ "checklists.props.distributor-status" | translate }}</th>
                        <th id="distributorObj.asm" [class]="{ 'd-none': !showAsm }">{{ "checklists.props.distributor-asm" | translate }}</th>
                        <th id="user">{{ "checklists.props.user" | translate }}</th>

                        <th id="visited">{{ "checklists.props.visited" | translate }}</th>
                        <th id="actions"></th>
                    </tr>
                </thead>
                <tbody *ngIf="rows?.length != 0">
                    <tr *ngFor="let row of rows" [ngClass]="{ marked: row.marked }">
                        <td>
                            <input type="checkbox" id="selected_{{ row._id }}" [(ngModel)]="selectedRows[row._id]" />
                        </td>
                        <td class="status" [popover]="statusTemplate" [popoverContext]="{ row: row }" placement="bottom" triggers="mouseenter:mouseleave">
                            <span>
                                <i *ngIf="row.differenceDetected" class="zmdi zmdi-flash text-danger mx-1"></i>
                                <i *ngIf="!row.differenceDetected" class="zmdi zmdi-assignment-check text-success mx-1"></i>
                            </span>
                            <span>
                                <i *ngIf="row.warningNoticesDetected === true" class="zmdi zmdi-alert-triangle text-danger mx-1"></i>
                                <i *ngIf="row.warningNoticesDetected === undefined" class="zmdi zmdi-pin-help text-warning mx-1"></i>
                                <i *ngIf="row.warningNoticesDetected === false" class="zmdi zmdi-assignment-check text-success mx-1"></i>
                            </span>
                            <span [ngSwitch]="row.approvalStatus()">
                                <i *ngSwitchCase="status.Neutral" class="zmdi zmdi-badge-check text-warning mx-1"></i>
                                <i *ngSwitchCase="status.Approved" class="zmdi zmdi-badge-check text-info mx-1"></i>
                                <i *ngSwitchCase="status.Confirmed" class="zmdi zmdi-badge-check text-success mx-1"></i>
                            </span>
                            <span [ngSwitch]="row.distributorAccessible()">
                                <i *ngSwitchCase="distributorAccessible.Unknown" class="zmdi zmdi-help text-warning mx-1"></i>
                                <i *ngSwitchCase="distributorAccessible.Accessible" class="zmdi zmdi-eye text-success mx-1"></i>
                                <i *ngSwitchCase="distributorAccessible.NotAccessible" class="zmdi zmdi-minus-circle text-danger mx-1"></i>
                            </span>
                        </td>
                        <td class="distributor">{{ row.distributorObj ? row.formatDistributor() : "" }}<BR />({{ row.distributor?.vo ?? "" }})</td>
                        <td class="distributorObj.status">{{ row.distributorObj.status }}</td>
                        <td class="asm" [class]="{ 'd-none': !showAsm }">{{ row.distributorObj.asm }}</td>
                        <td class="user">{{ row.userObj ? row.formatUser() : "" }}</td>

                        <td *ngIf="!row.hideVisitedDate">{{ row.visited | date : UTCDATEFORMAT }}</td>
                        <td *ngIf="row.hideVisitedDate">{{ row.visited | date : "MM/yyyy" }}</td>

                        <td class="text-right nowrap">
                            <i (click)="downloadChecklist(row._id)" class="zmdi zmdi-download zmdi-hc-fw tableIcon" *appHasPermission="'CHECKLIST.READ'" [tooltip]="'checklists.buttontooltips.downloadPDF' | translate" placement="bottom"></i>
                            <i (click)="viewChecklist(row._id)" class="zmdi zmdi-eye zmdi-hc-fw tableIcon" *appHasPermission="'CHECKLIST.EDIT'" [tooltip]="'checklists.buttontooltips.view' | translate" placement="bottom"></i>
                            <span *appHasPermission="'CHECKLIST.UPDATE.MARK'">
                                <i (click)="markChecklist(row._id)" class="zmdi zmdi-bookmark zmdi-hc-fw tableIcon" *ngIf="!row.marked" [tooltip]="'checklists.buttontooltips.mark' | translate" placement="bottom"></i>
                                <i (click)="unmarkChecklist(row._id)" class="zmdi zmdi-bookmark-outline zmdi-hc-fw tableIcon" *ngIf="row.marked" [tooltip]="'checklists.buttontooltips.unmark' | translate" placement="bottom"></i>
                            </span>
                            <span *appHasPermission="'CHECKLIST.UPDATE.APPROVE'">
                                <i (click)="approveChecklist(row._id)" class="zmdi zmdi-check zmdi-hc-fw tableIcon" *ngIf="!row.approved" [tooltip]="'checklists.buttontooltips.approve' | translate" placement="bottom"></i>
                                <i (click)="unapproveChecklist(row._id)" class="zmdi zmdi-check zmdi-hc-fw tableIcon text-danger" *ngIf="row.approved" [tooltip]="'checklists.buttontooltips.unapprove' | translate" placement="bottom"></i>
                            </span>
                            <span *appHasPermission="'CHECKLIST.UPDATE.CONFIRM'">
                                <i (click)="confirmChecklist(row._id)" class="zmdi zmdi-check-all zmdi-hc-fw tableIcon" *ngIf="!row.approvalConfirmed" [tooltip]="'checklists.buttontooltips.confirm' | translate" placement="bottom"></i>
                                <i
                                    (click)="unconfirmChecklist(row._id)"
                                    class="zmdi zmdi-check-all zmdi-hc-fw tableIcon text-danger"
                                    *ngIf="row.approvalConfirmed"
                                    [tooltip]="'checklists.buttontooltips.unconfirm' | translate"
                                    placement="bottom"
                                ></i>
                            </span>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="rows?.length == 0">
                    <tr>
                        <td colspan="6" class="no-data-available">{{ "global.datatable.empty" | translate }}</td>
                    </tr>
                </tbody>

                <tbody></tbody>
            </table>

            <div class="col-12 mt-5">
                <span *appHasPermission="'CHECKLIST.UPDATE.CONFIRM'">
                    <button type="button" (click)="confirmSelectedChecklists()" class="btn btn-success btn--icon-text float-right ml-2 mb-2" [disabled]="!isAtLeastOneChecklistSelected()">
                        <i class="zmdi zmdi-check-all"></i> {{ "checklists.buttons.confirmSelected" | translate }}
                    </button>
                </span>

                <span *appHasPermission="'CHECKLIST.UPDATE.APPROVE'">
                    <button type="button" (click)="approveSelectedChecklists()" class="btn btn-success btn--icon-text float-right ml-2 mb-2" [disabled]="!isAtLeastOneChecklistSelected()">
                        <i class="zmdi zmdi-check"></i> {{ "checklists.buttons.approveSelected" | translate }}
                    </button>
                </span>

                <span *appHasPermission="'CHECKLIST.UPDATE.MARK'">
                    <button type="button" (click)="markSelectedChecklists()" class="btn btn-success btn--icon-text float-right ml-2 mb-2" [disabled]="!isAtLeastOneChecklistSelected()">
                        <i class="zmdi zmdi-bookmark"></i> {{ "checklists.buttons.markSelected" | translate }}
                    </button>
                </span>

                <span *appHasPermission="'CHECKLIST.READ'">
                    <button
                        type="button"
                        (click)="downloadSelectedChecklists()"
                        class="btn btn--icon-text float-right mb-2"
                        [class.btn-success]="!isDownloadSelectedChecklistsProcessing()"
                        [class.btn-secondary]="isDownloadSelectedChecklistsProcessing()"
                        [disabled]="!isAtLeastOneChecklistSelected()"
                    >
                        <span *ngIf="!isDownloadSelectedChecklistsProcessing()"> <i class="zmdi zmdi-download"></i> {{ "checklists.buttons.downloadSelected" | translate }} </span>
                        <span *ngIf="downloadingMultipleChecklistsInProgress"> {{ "checklists.buttons.downloadSelectedLoading" | translate }} </span>
                        <span *ngIf="zippingMultipleChecklistsInProgress"> {{ "checklists.buttons.downloadSelectedZipping" | translate }} </span>
                    </button>

                    <div *ngIf="isDownloadSelectedChecklistsProcessing()" class="float-right">
                        <div class="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </span>
            </div>
        </div>
    </div>

    <ng-template #statusTemplate let-row="row">
        <div class="status-popover">
            <div class="status mb-3">
                <div class="title">
                    <b>{{ "checklists.overview.status.popover.title" | translate }}</b>
                </div>

                <div class="description">
                    <span *ngIf="row.differenceDetected"
                        ><i class="zmdi zmdi-flash text-danger icon"></i><span class="text">{{ "checklists.overview.status.popover.differencedetected" | translate }}</span></span
                    >
                    <span *ngIf="!row.differenceDetected"
                        ><i class="zmdi zmdi-assignment-check text-success icon"></i><span class="text">{{ "checklists.overview.status.popover.nodifferencedetected" | translate }}</span></span
                    >
                </div>

                <div class="description">
                    <span *ngIf="row.warningNoticesDetected === true"
                        ><i class="zmdi zmdi-alert-triangle text-danger icon"></i><span class="text">{{ "checklists.overview.status.popover.warningNoticesDetected" | translate }}</span></span
                    >
                    <span *ngIf="row.warningNoticesDetected === undefined"
                        ><i class="zmdi zmdi-assignment-check text-warning icon"></i><span class="text">{{ "checklists.overview.status.popover.warningsunknown" | translate }}</span></span
                    >
                    <span *ngIf="row.warningNoticesDetected === false"
                        ><i class="zmdi zmdi-assignment-check text-success icon"></i><span class="text">{{ "checklists.overview.status.popover.noWarningNoticesDetected" | translate }}</span></span
                    >
                </div>
                <div class="description" [ngSwitch]="row.approvalStatus()">
                    <span *ngSwitchCase="status.Neutral"
                        ><i class="zmdi zmdi-badge-check text-warning icon"></i><span class="text">{{ "checklists.overview.status.popover.neutral" | translate }}</span></span
                    >
                    <span *ngSwitchCase="status.Approved"
                        ><i class="zmdi zmdi-badge-check text-info icon"></i><span class="text">{{ "checklists.overview.status.popover.approved" | translate }}</span></span
                    >
                    <span *ngSwitchCase="status.Confirmed"
                        ><i class="zmdi zmdi-badge-check text-success icon"></i><span class="text">{{ "checklists.overview.status.popover.confirmed" | translate }}</span></span
                    >
                </div>
                <div class="description" [ngSwitch]="row.distributorAccessible()">
                    <span *ngSwitchCase="distributorAccessible.Unknown"
                        ><i class="zmdi zmdi-help text-warning icon"></i><span class="text">{{ "checklists.overview.distributorAccess.popover.unknown" | translate }}</span></span
                    >
                    <span *ngSwitchCase="distributorAccessible.Accessible"
                        ><i class="zmdi zmdi-eye text-success icon"></i><span class="text">{{ "checklists.overview.distributorAccess.popover.accessible" | translate }}</span></span
                    >
                    <span *ngSwitchCase="distributorAccessible.NotAccessible"
                        ><i class="zmdi zmdi-minus-circle text-danger icon"></i><span class="text">{{ "checklists.overview.distributorAccess.popover.notAccessible" | translate }}</span></span
                    >
                </div>
            </div>
        </div>
    </ng-template>
</div>
