<div class="content__inner">
    <header class="content__title">
        <h1>{{ "checklisttemplate.title" | translate }}</h1>
        <small>{{ "checklisttemplate.subtitle" | translate }}</small>
    </header>

    <div class="card" *appHasPermission="'CHECKLISTTEMPLATE.READ'">
        <div class="card-body">
            <div class="actions">
                <span *appHasPermission="'CHECKLISTTEMPLATE.UPDATE'">
                    <button type="button" (click)="migrateAll()" class="btn btn-success btn--icon-text ml-2 mb-2"><i class="zmdi zmdi-bookmark"></i> {{ "checklisttemplate.migration.button" | translate }}</button>
                </span>
            </div>

            <div class="nav nav-tabs">
                <a *ngFor="let template of templates" [routerLink]="['/checklisttemplate', template.templateNumber]" class="nav-link" routerLinkActive="active">{{
                    "checklisttemplate.template" | translate : { number: template.templateNumber }
                }}</a>
                <button *ngIf="nextTemplateNumber" class="nav-link" (click)="showCreateNewChecklistTemplateModal(nextTemplateNumber)">
                    {{ "checklisttemplate.createTemplateButton" | translate : { number: nextTemplateNumber } }}
                </button>
            </div>

            <form name="form" class="row" *ngIf="checklistTemplate" (ngSubmit)="f.form.valid && save()" #f="ngForm" novalidate>
                <div class="col-md-12">
                    <tabset>
                        <tab>
                            <ng-template tabHeading
                                ><span [ngClass]="{ 'text-danger': f.submitted && agreement.invalid }">{{ "checklisttemplate.agreement.title" | translate }}</span></ng-template
                            >

                            <div class="form-group">
                                <div>
                                    <button (click)="toggleAgreementEditorMode()" class="btn btn-success btn--icon-text upload-flyer-image-buttom" type="button">
                                        <i class="zmdi zmdi-collection-plus"></i>
                                        <span *ngIf="agreementWYSIWYGMode">{{ "checklisttemplate.editor.toggletohtml" | translate }}</span>
                                        <span *ngIf="!agreementWYSIWYGMode">{{ "checklisttemplate.editor.toggletowysiwyg" | translate }}</span>
                                    </button>
                                </div>
                                <br />

                                <input #agreement="ngModel" name="agreement" [(ngModel)]="checklistTemplate.agreement" [ngClass]="{ 'is-invalid': f.submitted && agreement.invalid }" required hidden />

                                <quill-editor name="checklistTemplateAgreement" [(ngModel)]="checklistTemplate.agreement" *ngIf="agreementWYSIWYGMode"></quill-editor>

                                <div
                                    ngxAceEditor
                                    [(text)]="checklistTemplate.agreement"
                                    [mode]="settingsHTML.mode"
                                    [options]="settingsHTML.options"
                                    [theme]="'eclipse'"
                                    [autoUpdateContent]="true"
                                    style="min-height: 442px; width: 100%; overflow: auto"
                                    *ngIf="!agreementWYSIWYGMode"
                                ></div>

                                <div class="invalid-tooltip">
                                    <div *ngFor="let error of agreement.errors | keyvalue | slice : 0 : 1">
                                        {{ "checklisttemplate.agreement.validation." + error.key | translate }}
                                    </div>
                                </div>
                                <i class="form-group__bar"></i>
                            </div>
                        </tab>

                        <tab>
                            <ng-template tabHeading
                                ><span [ngClass]="{ 'text-danger': f.submitted && content.invalid }">{{ "checklisttemplate.content.title" | translate }}</span></ng-template
                            >

                            <div class="form-group">
                                <div class="row mx-0">
                                    <button (click)="beautify('content')" class="btn btn-success btn--icon-text upload-flyer-image-buttom col-auto" type="button">
                                        <i class="zmdi zmdi-collection-plus"></i> {{ "checklisttemplate.editor.beautify" | translate }}
                                    </button>
                                    <span class="text-danger col flex-grow-1 align-self-center ml-3 py-2 border border-danger">{{ "checklisttemplate.content.warning" | translate }}</span>
                                </div>
                                <br />

                                <input #content="ngModel" name="content" [(ngModel)]="checklistTemplateTemporary.content" [ngClass]="{ 'is-invalid': f.submitted && content.invalid }" required appValidJson hidden />

                                <div
                                    ngxAceEditor
                                    [(text)]="checklistTemplateTemporary.content"
                                    [mode]="settingsJSON.mode"
                                    [options]="settingsJSON.options"
                                    [theme]="'eclipse'"
                                    [autoUpdateContent]="true"
                                    style="min-height: 442px; width: 100%; overflow: auto"
                                ></div>

                                <div class="invalid-tooltip">
                                    <div *ngFor="let error of content.errors | keyvalue | slice : 0 : 1">
                                        {{ "checklisttemplate.content.validation." + error.key | translate }}
                                    </div>
                                </div>
                                <i class="form-group__bar"></i>
                            </div>
                        </tab>

                        <tab>
                            <ng-template tabHeading
                                ><span [ngClass]="{ 'text-danger': f.submitted && photosNormal.invalid }">{{ "checklisttemplate.photosNormal.title" | translate }}</span></ng-template
                            >

                            <div class="form-group">
                                <div>
                                    <button (click)="beautify('photosNormal')" class="btn btn-success btn--icon-text upload-flyer-image-buttom" type="button">
                                        <i class="zmdi zmdi-collection-plus"></i> {{ "checklisttemplate.editor.beautify" | translate }}
                                    </button>
                                </div>
                                <br />

                                <input #photosNormal="ngModel" name="photosNormal" [(ngModel)]="checklistTemplateTemporary.photosNormal" [ngClass]="{ 'is-invalid': f.submitted && photosNormal.invalid }" required appValidJson hidden />

                                <div
                                    ngxAceEditor
                                    [(text)]="checklistTemplateTemporary.photosNormal"
                                    [mode]="settingsJSON.mode"
                                    [options]="settingsJSON.options"
                                    [theme]="'eclipse'"
                                    [autoUpdateContent]="true"
                                    style="min-height: 442px; width: 100%; overflow: auto"
                                ></div>

                                <div class="invalid-tooltip">
                                    <div *ngFor="let error of photosNormal.errors | keyvalue | slice : 0 : 1">
                                        {{ "checklisttemplate.photosNormal.validation." + error.key | translate }}
                                    </div>
                                </div>
                                <i class="form-group__bar"></i>
                            </div>
                        </tab>

                        <tab>
                            <ng-template tabHeading
                                ><span [ngClass]="{ 'text-danger': f.submitted && photosSilber.invalid }">{{ "checklisttemplate.photosSilber.title" | translate }}</span></ng-template
                            >

                            <div class="form-group">
                                <div>
                                    <button (click)="beautify('photosSilber')" class="btn btn-success btn--icon-text upload-flyer-image-buttom" type="button">
                                        <i class="zmdi zmdi-collection-plus"></i> {{ "checklisttemplate.editor.beautify" | translate }}
                                    </button>
                                </div>
                                <br />

                                <input #photosSilber="ngModel" name="photosSilber" [(ngModel)]="checklistTemplateTemporary.photosSilber" [ngClass]="{ 'is-invalid': f.submitted && photosSilber.invalid }" required appValidJson hidden />

                                <div
                                    ngxAceEditor
                                    [(text)]="checklistTemplateTemporary.photosSilber"
                                    [mode]="settingsJSON.mode"
                                    [options]="settingsJSON.options"
                                    [theme]="settingsJSON.theme"
                                    [autoUpdateContent]="true"
                                    style="min-height: 442px; width: 100%; overflow: auto"
                                ></div>

                                <div class="invalid-tooltip">
                                    <div *ngFor="let error of photosSilber.errors | keyvalue | slice : 0 : 1">
                                        {{ "checklisttemplate.photosSilber.validation." + error.key | translate }}
                                    </div>
                                </div>
                                <i class="form-group__bar"></i>
                            </div>
                        </tab>

                        <tab>
                            <ng-template tabHeading
                                ><span [ngClass]="{ 'text-danger': f.submitted && photosGold.invalid }">{{ "checklisttemplate.photosGold.title" | translate }}</span></ng-template
                            >

                            <div class="form-group">
                                <div>
                                    <button (click)="beautify('photosGold')" class="btn btn-success btn--icon-text upload-flyer-image-buttom" type="button">
                                        <i class="zmdi zmdi-collection-plus"></i> {{ "checklisttemplate.editor.beautify" | translate }}
                                    </button>
                                </div>
                                <br />

                                <input #photosGold="ngModel" name="photosGold" [(ngModel)]="checklistTemplateTemporary.photosGold" [ngClass]="{ 'is-invalid': f.submitted && photosGold.invalid }" required appValidJson hidden />

                                <div
                                    ngxAceEditor
                                    [(text)]="checklistTemplateTemporary.photosGold"
                                    [mode]="settingsJSON.mode"
                                    [options]="settingsJSON.options"
                                    [theme]="settingsJSON.theme"
                                    [autoUpdateContent]="true"
                                    style="min-height: 442px; width: 100%; overflow: auto"
                                ></div>

                                <div class="invalid-tooltip">
                                    <div *ngFor="let error of photosGold.errors | keyvalue | slice : 0 : 1">
                                        {{ "checklisttemplate.photosGold.validation." + error.key | translate }}
                                    </div>
                                </div>
                                <i class="form-group__bar"></i>
                            </div>
                        </tab>
                    </tabset>
                </div>

                <div class="col-md-12" *appHasPermission="'CHECKLISTTEMPLATE.UPDATE'">
                    <button class="float-right btn btn-success btn--icon-text" [disabled]="f.submitted && f.invalid"><i class="zmdi zmdi-check"></i> {{ "global.buttons.save" | translate }}</button>
                </div>
            </form>
        </div>
    </div>
</div>
