import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { map } from "rxjs/operators";
import { classToPlain, plainToClass } from "class-transformer";
import { ChecklistTemplate } from "../../api-models/ChecklistTemplate";

export interface IChecklistTemplateConfigService {
    checklistTemplateAPI: string;
    utcDateFormat: string;
}

export const ChecklistTemplateConfigService = new InjectionToken<IChecklistTemplateConfigService>("ChecklistTemplateConfig");

export class ChecklistTemplateListItemDTO {
    list: ChecklistTemplate[];
    nextTemplateNumber: number;
}

@Injectable()
export class ChecklistTemplateService {
    public UTCDATEFORMAT: string = "";

    constructor(@Inject(ChecklistTemplateConfigService) private checklistTemplateConfig: IChecklistTemplateConfigService, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {
        this.UTCDATEFORMAT = this.checklistTemplateConfig.utcDateFormat;
    }

    public list(): Observable<{ result: ChecklistTemplateListItemDTO; count: number; total: number }> {
        return this.http
            .get<object>(this.getChecklistTemplateServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                observe: "response"
            })
            .pipe(
                map((result) => {
                    const resultInstance = plainToClass(ChecklistTemplateListItemDTO, result.body);

                    let count = resultInstance.list.length ?? 0;
                    let total = resultInstance.list.length ?? 0;

                    try {
                        count = parseInt(result.headers.get("X-Pagination-Count") ?? "0", 10);
                    } catch (e) {
                        console.warn("no x-pagination-count found for overtime list");
                    }
                    try {
                        total = parseInt(result.headers.get("X-Total-Count") ?? "0", 10);
                    } catch (e) {
                        console.warn("no x-total-count found for overtime list");
                    }

                    return {
                        result: resultInstance,
                        count: count,
                        total: total
                    };
                })
            );
    }

    public get(idOrTemplateNumber: string): Observable<ChecklistTemplate> {
        return this.http
            .get<Object>(this.getChecklistTemplateServiceUrl(idOrTemplateNumber), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(ChecklistTemplate, result)));
    }

    public update(idOrTemplateNumber: string, checklistTemplate: ChecklistTemplate): Observable<ChecklistTemplate> {
        return this.http
            .patch<Object>(this.getChecklistTemplateServiceUrl(idOrTemplateNumber), classToPlain(checklistTemplate), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(ChecklistTemplate, result)));
    }

    public migrateAll(): Observable<{ remainingCount: number; migrated: number; total: number }> {
        return this.http.get<{ remainingCount: number; migrated: number; total: number }>(this.getChecklistTemplateServiceUrl("migrate"), {
            headers: this.oauthStorageAdapter.getAuthHeadersJSON()
        });
    }

    private getChecklistTemplateServiceUrl(postfix: string): string {
        return this.checklistTemplateConfig.checklistTemplateAPI + postfix;
    }
}
