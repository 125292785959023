import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { QuillModule } from "ngx-quill";
import { AceEditorModule } from "@postfinance/ngx-ace-editor-wrapper";
import { ValidJsonModule } from "src/app/directive/valid-json/valid-json.module";
import { TabsModule } from "ngx-bootstrap/tabs";

import { ChecklistTemplateComponent } from "./checklisttemplate.component";
import { ChecklistTemplateService, ChecklistTemplateConfigService, IChecklistTemplateConfigService } from "./checklisttemplate.service";
import { TranslateModule } from "@ngx-translate/core";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { Route, RouterModule } from "@angular/router";
import { ChecklistTemplateMainComponent } from "./checklisttemplate.main.component";

@NgModule({
    declarations: [ChecklistTemplateMainComponent, ChecklistTemplateComponent],
    imports: [CommonModule, RouterModule, FormsModule, QuillModule, AceEditorModule, ValidJsonModule, TabsModule, BsDropdownModule, ModalModule, NgSelectModule, TranslateModule, OAuthModule],
    exports: [],
    providers: []
})
export class ChecklistTemplateModule {
    static forRoot(checklistTemplateConfig: IChecklistTemplateConfigService): ModuleWithProviders<ChecklistTemplateModule> {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: ChecklistTemplateModule,
            providers: [
                ChecklistTemplateService,
                {
                    provide: ChecklistTemplateConfigService,
                    useValue: checklistTemplateConfig
                }
            ]
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                component: ChecklistTemplateMainComponent,
                children: [
                    {
                        path: "",
                        component: ChecklistTemplateComponent
                    },
                    {
                        path: ":templateNumber",
                        component: ChecklistTemplateComponent
                    }
                ]
            }
        ];
    }
}
