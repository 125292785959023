import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, BaseComponent, BaseComponentMode, ConfirmDialogComponent } from "@impacgroup/angular-baselib";
import { PostcodeUsersService } from "./postcodeusers.service";
import { TranslateService } from "@ngx-translate/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { PostcodeAreaDetails, UserDetails } from "src/app/api-models/PostcodeUser";
import { PermissionService } from "@impacgroup/angular-oauth-base";

@Component({
    selector: "app-postcodeuserdetail",
    templateUrl: "./postcodeuserdetail.component.html",
    styleUrls: ["postcodeuserdetail.component.scss"]
})
export class PostcodeUserDetailComponent extends BaseComponent implements OnInit {
    public canAddPostcodes = false;
    public canDeletePostcodes = false;
    public postcodeUser: UserDetails = null;

    public assignedPostcodes: PostcodeAreaDetails[] = [];
    public unassignedPostcodes: PostcodeAreaDetails[] = [];

    private userId: string = null;

    constructor(
        private route: ActivatedRoute,
        private permissionService: PermissionService,
        private postcodeUsersService: PostcodeUsersService,
        private router: Router,
        private alertService: AlertService,
        private translateService: TranslateService,
        private _location: Location,
        private modalService: BsModalService
    ) {
        super();

        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                this.userId = params.id;
                if (this.userId) {
                    if (permissionService.hasPermission("POSTCODEUSERS.ADD") || permissionService.hasPermission("POSTCODEUSERS.DELETE")) {
                        this.initEditMode();
                    } else {
                        this.initViewMode();
                    }
                } else {
                    this._location.back();
                }
            })
        );
    }

    ngOnInit(): void {}

    initViewMode() {
        this.MODE = BaseComponentMode.VIEW;
        this.loadUserDetails();
    }

    initEditMode() {
        this.MODE = BaseComponentMode.EDIT;
        this.canAddPostcodes = this.permissionService.hasPermission("POSTCODEUSERS.ADD");
        this.canDeletePostcodes = this.permissionService.hasPermission("POSTCODEUSERS.DELETE");

        this.loadUserDetails();
    }

    back() {
        this._location.back();
    }

    private loadUserDetails() {
        const loadUserDetailsSub = this.postcodeUsersService.read(this.userId).subscribe(
            (result) => {
                this.postcodeUser = result.userDetails;
                this.assignedPostcodes = result.assignedPostcodes ?? [];
                this.unassignedPostcodes = result.unassignedPostcodes ?? [];
            },
            (err) => {
                this.router.navigate(["../"], { relativeTo: this.route });
            }
        );

        this.subscriptions.push(loadUserDetailsSub);
    }

    changeUserCheckbox(event, postcode) {
        const newCheckboxState = event.target.checked;
        // Prevent toggling checkbox until the modal is answered (or not)
        event.target.checked = !newCheckboxState;
        postcode.checked = !newCheckboxState;
        if (newCheckboxState === true) {
            this.assignUserTo(postcode);
        } else {
            this.unassignUserFrom(postcode);
        }
    }

    private unassignUserFrom(postcode) {
        const initialState = {
            title: this.translateService.instant("postcodeusers.detail.assignedPostcodes.delete.title"),
            message: this.translateService.instant("postcodeusers.detail.assignedPostcodes.delete.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        bsModalRef.content.confirmCallback = () => {
            this.subscriptions.push(
                this.postcodeUsersService.delete(this.userId, postcode._id).subscribe((result) => {
                    this.postcodeUser = result.userDetails;
                    this.assignedPostcodes = result.assignedPostcodes ?? [];
                    this.unassignedPostcodes = result.unassignedPostcodes ?? [];

                    this.alertService.addSuccess(this.translateService.instant("postcodeusers.detail.assignedPostcodes.delete.success"));
                })
            );
        };
    }

    private assignUserTo(postcode) {
        this.subscriptions.push(
            this.postcodeUsersService.create(this.userId, postcode._id).subscribe((result) => {
                this.postcodeUser = result.userDetails;
                this.assignedPostcodes = result.assignedPostcodes ?? [];
                this.unassignedPostcodes = result.unassignedPostcodes ?? [];

                this.alertService.addSuccess(this.translateService.instant("postcodeusers.detail.assignedPostcodes.add.success"));
            })
        );
    }
}
