import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter, User } from "@impacgroup/angular-oauth-base";
import { IApiListQueryParameter } from "@impacgroup/angular-baselib";
import { map } from "rxjs/operators";
import { plainToClass } from "class-transformer";
import { PostcodeUserDetailResponseDTO, PostcodeUserListResponseDTO } from "@impacgroup/ayyildiz-checklist-platform-api-dtos";

export interface IPostcodeUserRepositoryConfig {
    postcodeUsersAPI: string;
}

export const PostcodeUserRepositoryConfig = new InjectionToken<IPostcodeUserRepositoryConfig>("PostcodeUserRepositoryConfig");

@Injectable()
export class PostcodeUsersRepository {
    constructor(@Inject(PostcodeUserRepositoryConfig) private postcodeUserRepositoryConfig: IPostcodeUserRepositoryConfig, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {}

    public list(params: IApiListQueryParameter): Observable<{ list: PostcodeUserListResponseDTO[]; count: number; total: number }> {
        let queryPramas: {} = {
            ...params
        };

        return this.http
            .get<Object[]>(this.getServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params: queryPramas,
                observe: "response"
            })
            .pipe(
                map((result) => {
                    let count = result.body?.length ?? 0;
                    let total = result.body?.length ?? 0;
                    try {
                        count = parseInt(result.headers.get("X-Pagination-Count") ?? "0", 10);
                    } catch (e) {}
                    try {
                        total = parseInt(result.headers.get("X-Total-Count") ?? "0", 10);
                    } catch (e) {}

                    return {
                        list: plainToClass(PostcodeUserListResponseDTO, result.body ?? [], { excludeExtraneousValues: true }),
                        count: count,
                        total: total
                    };
                })
            );
    }

    public read(userId: string): Observable<PostcodeUserDetailResponseDTO> {
        return this.http
            .get<Object>(this.getServiceUrl(userId), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(PostcodeUserDetailResponseDTO, result, { excludeExtraneousValues: true })));
    }

    public create(userId: string, postCodeId: string): Observable<PostcodeUserDetailResponseDTO> {
        return this.http
            .post<Object>(this.getServiceUrl(`${userId}/${postCodeId}`), null, {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(PostcodeUserDetailResponseDTO, result, { excludeExtraneousValues: true })));
    }

    public delete(userId: string, postCodeId: string): Observable<PostcodeUserDetailResponseDTO> {
        return this.http
            .delete<PostcodeUserDetailResponseDTO>(this.getServiceUrl(`${userId}/${postCodeId}`), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(PostcodeUserDetailResponseDTO, result, { excludeExtraneousValues: true })));
    }

    private getServiceUrl(postfix: string): string {
        return this.postcodeUserRepositoryConfig.postcodeUsersAPI + postfix;
    }
}
