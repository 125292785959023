import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";

import { PostcodeAreasComponent } from "./postcodeareas.component";
import { PostcodeAreasService, PostcodeAreaConfigService, IPostcodeAreaConfigService } from "./postcodeareas.service";
import { PostcodeAreaDetailComponent } from "./postcodeareadetail.component";
import { TranslateModule } from "@ngx-translate/core";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { Route, RouterModule } from "@angular/router";
import { PostcodeAreasMainComponent } from "./postcodeareas.main.component";
import { ValidDeZipCodeModule } from "src/app/directive/valid-de-zip-code/valid-de-zip-code.module";
import { PostcodeUsersMainComponent } from "./postcodeusers.main.component";
import { PostcodeUsersComponent } from "./postcodeusers.component";
import { PostcodeUserDetailComponent } from "./postcodeuserdetail.component";
import { IPostcodeUserConfigService, PostcodeUserServiceConfig, PostcodeUsersService } from "./postcodeusers.service";
import { IPostcodeUserRepositoryConfig, PostcodeUserRepositoryConfig, PostcodeUsersRepository } from "./postcodeusers.repository";

@NgModule({
    declarations: [PostcodeAreasMainComponent, PostcodeAreasComponent, PostcodeAreaDetailComponent, PostcodeUsersMainComponent, PostcodeUsersComponent, PostcodeUserDetailComponent],
    imports: [CommonModule, RouterModule, FormsModule, DataTablesModule, BsDropdownModule, ValidDeZipCodeModule, ModalModule, NgSelectModule, TranslateModule, OAuthModule],
    exports: [],
    providers: []
})
export class PostcodeAreasModule {
    static forRoot(postcodeAreaConfig: IPostcodeAreaConfigService & IPostcodeUserConfigService & IPostcodeUserRepositoryConfig): ModuleWithProviders<PostcodeAreasModule> {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: PostcodeAreasModule,
            providers: [
                PostcodeAreasService,
                {
                    provide: PostcodeAreaConfigService,
                    useValue: postcodeAreaConfig
                },
                PostcodeUsersService,
                {
                    provide: PostcodeUserServiceConfig,
                    useValue: postcodeAreaConfig
                },
                PostcodeUsersRepository,
                {
                    provide: PostcodeUserRepositoryConfig,
                    useValue: postcodeAreaConfig
                }
            ]
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                redirectTo: "overview",
                pathMatch: "full"
            },
            {
                path: "overview",
                component: PostcodeAreasMainComponent,
                children: [
                    {
                        path: "",
                        component: PostcodeAreasComponent
                    },
                    {
                        path: "add",
                        component: PostcodeAreaDetailComponent
                    },
                    {
                        path: ":id",
                        component: PostcodeAreaDetailComponent
                    }
                ]
            },
            {
                path: "users",
                component: PostcodeUsersMainComponent,
                children: [
                    {
                        path: "",
                        component: PostcodeUsersComponent
                    },
                    {
                        path: ":id",
                        component: PostcodeUserDetailComponent
                    }
                ]
            }
        ];
    }
}
