<div class="content__inner">
    <header class="content__title">
        <h1>{{ "postcodeusers.list.title" | translate }}</h1>
        <small>{{ "postcodeusers.list.subtitle" | translate }}</small>
    </header>

    <div class="card" *appHasPermission="'POSTCODEUSERS.LIST'">
        <div class="card-body">
            <h4 class="card-title">{{ "postcodeusers.list.card.title" | translate }}</h4>

            <table datatable class="table table-bordered" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th id="name">{{ "postcodeusers.props.firstname" | translate }}</th>
                        <th id="link">{{ "postcodeusers.props.lastname" | translate }}</th>
                        <th id="link">{{ "postcodeusers.props.email" | translate }}</th>
                        <th id="created">{{ "postcodeusers.props.created" | translate }}</th>
                        <th id="updated">{{ "postcodeusers.props.updated" | translate }}</th>
                        <th id="actions"></th>
                    </tr>
                </thead>
                <tbody *ngIf="rows?.length != 0">
                    <tr *ngFor="let row of rows">
                        <td>{{ row.firstname }}</td>
                        <td>{{ row.lastname }}</td>
                        <td>{{ row.email }}</td>
                        <td>{{ row.created | date : UTCDATEFORMAT : "+0" }}</td>
                        <td>{{ row.updated | date : UTCDATEFORMAT : "+0" }}</td>
                        <td class="text-right nowrap">
                            <i (click)="showUserPostcodes(row._id)" class="zmdi zmdi-edit zmdi-hc-fw tableIcon" *appHasPermission="'POSTCODEUSERS.READ'"></i>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="rows?.length == 0">
                    <tr>
                        <td colspan="6" class="no-data-available">{{ "global.datatable.empty" | translate }}</td>
                    </tr>
                </tbody>

                <tbody></tbody>
            </table>
        </div>
    </div>
</div>
